import React, { useEffect, useRef } from "react";
import Img from "gatsby-image";
import PropTypes from "prop-types";
import { gsap } from "gsap";

import Heading from "components/Heading";
import Button from "components/Button";

import "./_hero.scss";

const Hero = ({ title, text, image, imageAlt }) => {
  let titleRef = useRef(null);
  let textRef = useRef(null);
  let buttonRef = useRef(null);
  const tl = gsap.timeline();

  useEffect(() => {
    tl.fromTo(
      titleRef,
      1,
      { autoAlpha: 0, y: "40px" },
      { autoAlpha: 1, y: "0px", stagger: 0.1 }
    )
      .fromTo(
        textRef,
        1,
        { autoAlpha: 0, y: "40px" },
        { autoAlpha: 1, y: "0px", stagger: 0.1 },
        "-=0.5"
      )
      .fromTo(
        buttonRef,
        1,
        { autoAlpha: 0, y: "40px" },
        { autoAlpha: 1, y: "0px", stagger: 0.1 },
        "-=0.5"
      );
  });

  return (
    <div className="hero">
      <div className="hero__info">
        <div
          ref={el => {
            titleRef = el;
          }}
          className="hero__heading"
        >
          <Heading headingLevel="h1">{title}</Heading>
        </div>
        <p
          ref={el => {
            textRef = el;
          }}
          className="hero__text"
        >
          {text}
        </p>
        <div
          ref={el => {
            buttonRef = el;
          }}
          className="hero__button"
        >
          <Button url="#praktikos-sritys">Praktikos sritys</Button>
        </div>
      </div>
      <div className="hero__image">
        <Img fluid={image} alt={imageAlt} />
      </div>
    </div>
  );
};

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  imageAlt: PropTypes.string.isRequired,
};

export default Hero;
