import { Power2 } from "gsap";

export const textTransition = {
  duration: 1,
  from: {
    opacity: 0,
    y: "40px",
  },
  to: {
    opacity: 1,
    y: "0",
    ease: Power2.easeInOut,
  },
};
