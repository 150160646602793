import React from "react";
import { graphql } from "gatsby";

import navigation from "src/data";
import Header from "components/Header";
import Main from "components/Main";
import Hero from "components/Hero";
import InfoBlock from "components/InfoBlock";
import AboutMe from "components/AboutMe";
import Footer from "components/Footer";
import SEO from "components/SEO";

import "styles/styles.scss";

const IndexPage = ({ data }) => {
  return (
    <>
      <SEO />
      {data.allContentfulLanding.edges.map((edge, idx) => (
        <div key={idx}>
          <Header
            items={navigation}
            logo={edge.node.logo.fluid}
            logoAlt="Solaw Logotipas"
            navPosition="right"
          />
          <Main>
            <Hero
              title={edge.node.title}
              text={edge.node.heroText}
              image={edge.node.heroImage.fluid}
              imageAlt="Solaw sprendimai"
            />
            <InfoBlock
              heading={edge.node.aboutUsTitle}
              content={edge.node.aboutUs.childContentfulRichText.html}
            />
            <AboutMe
              heading={edge.node.aboutMeTitle}
              content={edge.node.aboutMe.childContentfulRichText.html}
              image={edge.node.myPhoto.fluid}
              linkedinUrl={edge.node.linkedin}
            />
            <AboutMe
              heading={edge.node.teamMember1Title}
              content={edge.node.teamMember1Info.childContentfulRichText.html}
              image={edge.node.teamMember1Photo.fluid}
              linkedinUrl={edge.node.teamMember1Linkedin}
            />
            <InfoBlock
              id="praktikos-sritys"
              heading={edge.node.servicesTitle}
              intro={edge.node.servicesIntro.servicesIntro}
              content={edge.node.services.childContentfulRichText.html}
            />
          </Main>
          <Footer />
        </div>
      ))}
    </>
  );
};

export const query = graphql`
  {
    allContentfulLanding {
      edges {
        node {
          title
          heroText
          linkedin
          heroImage {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          logo {
            fluid(maxWidth: 300, quality: 100) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          aboutUsTitle
          aboutUs {
            childContentfulRichText {
              html
            }
          }
          aboutMeTitle
          myPhoto {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          aboutMe {
            childContentfulRichText {
              html
            }
          }
          teamMember1Title
          teamMember1Photo {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          teamMember1Info {
            childContentfulRichText {
              html
            }
          }
          teamMember1Linkedin
          servicesTitle
          servicesIntro {
            servicesIntro
          }
          services {
            childContentfulRichText {
              html
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
