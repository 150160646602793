import React from "react";
import PropTypes from "prop-types";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import { ArrowIcon } from "src/assets/icons";

import "./_button.scss";

const Button = ({ isLink, url, children }) => {
  return (
    <>
      {isLink ? (
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="button"
        >
          {children}
          <ArrowIcon />
        </a>
      ) : (
        <AnchorLink to={`/${url}`} className="button">
          {children}
          <ArrowIcon />
        </AnchorLink>
      )}
    </>
  );
};

Button.propTypes = {
  url: PropTypes.string.isRequired,
  isLink: PropTypes.bool,
};

export default Button;
