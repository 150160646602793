import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";

import Heading from "components/Heading";
import { textTransition } from "components/Animations";

import "./_info-block.scss";

const InfoBlock = ({ id, heading, intro, content }) => {
  const trigger = useRef(null);
  const headingRef = useRef(null);
  const contentRef = useRef(null);
  const introRef = useRef(null);

  useEffect(() => {
    const tl = new gsap.timeline();
    const { duration, from, to } = textTransition;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          tl.fromTo(headingRef.current, duration, from, to).fromTo(
            contentRef.current,
            duration,
            from,
            to,
            "-=0.5"
          );
          if (introRef.current) {
            tl.fromTo(introRef.current, duration, from, to, "-=0.3");
          }
          observer.unobserve(trigger.current);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.3,
      }
    );
    if (trigger.current) {
      observer.observe(trigger.current);
    }
  });

  return (
    <div ref={trigger} className="info-block" id={id}>
      <div className="info-block__wrapper">
        <div ref={headingRef} className="info-block__heading-wrapper">
          <Heading headingLevel="h2">{heading}</Heading>
        </div>
        {intro ? (
          <p ref={introRef} className="info-block__intro">
            {intro}
          </p>
        ) : null}
      </div>
      <div
        ref={contentRef}
        className="info-block__text"
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    </div>
  );
};

export default InfoBlock;
