import React, { useRef, useEffect } from "react";
import Img from "gatsby-image";
import { gsap, Power2 } from "gsap";

import Heading from "components/Heading";
import Button from "components/Button";
import Visual from "components/Visual";
import { textTransition } from "components/Animations";

import "./_about-me.scss";

const AboutMe = ({ heading, content, image, linkedinUrl }) => {
  const trigger = useRef(null);
  const headingRef = useRef(null);
  const containerRef = useRef(null);
  const textRef = useRef(null);
  const buttonRef = useRef(null);
  const imageOverlayRef = useRef(null);

  useEffect(() => {
    const tl = new gsap.timeline();
    const { duration, from, to } = textTransition;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          tl.fromTo(headingRef.current, duration, from, to)
            .fromTo(textRef.current, duration, from, to, "-=0.7")
            .set(containerRef.current, { visibility: "visible" }, "-=1")
            .to(
              imageOverlayRef.current,
              1.4,
              {
                width: "0%",
                ease: Power2.easeInOut,
              },
              "-=1"
            )
            .fromTo(buttonRef.current, duration, from, to, "-=0.7");
          observer.unobserve(trigger.current);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.3,
      }
    );
    if (trigger.current) {
      observer.observe(trigger.current);
    }
  });

  return (
    <div ref={trigger} className="about-me" id="apie">
      <div className="about-me__text-wrapper">
        <div ref={headingRef} className="about-me__heading-wrapper">
          <Heading headingLevel="h2">{heading}</Heading>
        </div>
        <div
          ref={textRef}
          className="about-me__text"
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        ></div>
      </div>
      <div ref={containerRef} className="about-me__image-wrapper">
        <div className="about-me__inner-wrapper">
          <Img fluid={image} alt={heading} />

          <div ref={imageOverlayRef} className="about-me__image-overlay"></div>
        </div>
        <div ref={buttonRef} className="about-me__button">
          <Button isLink={true} url={linkedinUrl}>
            Advokatės CV
          </Button>
        </div>
        <Visual visualType="about-me" />
      </div>
    </div>
  );
};

export default AboutMe;
